import React from "react"
import styled from "styled-components"
import { FiPhone, FiGithub, FiMail, FiStar } from "react-icons/fi"
const Data = () => {
  return (
    <Container>
      {/*  <h1>John Diaz</h1>
      <h2>Front End Developer/ Creative Technologist</h2> */}
      <ul>
        {"  "}{" "}
        <li>
          <FiStar /> John Luis Miguelez Diaz
        </li>
        <li>
          <FiGithub /> https://github.com/Thebohemiangeek
        </li>
        <li>
          <FiMail /> citron@citronred.com
        </li>
      </ul>
    </Container>
  )
}

export default Data
const Container = styled.div`
  margin: 2rem auto;
  height: 480px;
  text-align: center;
  @media (max-width: 860px) {
    margin: 0.5rem auto;
    height: 320px;
  }
  h1 {
    font-size: 4rem;
  }
  ul {
    margin-top: 2rem;
    height: 280px;
    display: flex;
    text-decoration: none;
    list-style-type: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;

    li {
      font-size: 1.5rem;
      display: flex;
      align-items: center;
      svg {
        margin-right: 1em;
      }
    }
  }
`
