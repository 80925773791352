import React from "react"
import Layout from "../components/layout"
import Form from "../components/Contact/Form"
import Hero from "../components/Hero/Hero"
import SEO from "../components/seo"
import Data from "../components/Contact/Data"
import Title from "../components/Contact/Title"

import styled from "styled-components"
import { graphql } from "gatsby"

const contact = ({ data }) => {
  return (
    <Layout>
      <SEO title="Contact" />
      <Hero img={data.indexBanner.childImageSharp.fluid} />
      <Title />
      <Flex>
        <Form /> <Data />
      </Flex>
    </Layout>
  )
}

export default contact
export const query = graphql`
  query {
    indexBanner: file(relativePath: { eq: "banner5.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 2160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
const Flex = styled.div`
  display: flex;
  @media (max-width: 860px) {
    flex-direction: column-reverse;
  }
`
