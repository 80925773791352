import React from "react"
import styled from "styled-components"
const Title = () => {
  return <Head>Contact</Head>
}

export default Title
const Head = styled.div`
  color: white;
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: 7px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Poiret One", cursive;
  width: 100%;
  margin-top: 1.5rem;
`
